import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from 'styled-components'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import PageHeaderImageBgNew from '../../components/headers/PageHeaderImageBgNew'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query BlogPageNewQuery {
      prismicBlogPage {
        data {
          page_title
          meta_title
          meta_description
          page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          page_no_index
        }
      }
      allPrismicBlogPost(
        sort: {fields: data___date_published, order: DESC}
      ) {
        edges {
          node {
            id
            uid
            data {
              post_title
              post_hero_background {
                alt
                gatsbyImageData(
                  width: 1920
                )
              }
              post_hero_background_position
              post_excerpt
              date_published
            }
          }
        }
      }
    }
  `)

  const doc = data.prismicBlogPage
  const blogPosts = data.allPrismicBlogPost.edges
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgHero = getImage(doc.data.page_hero_background)

  return (
    <MainContainer>
      <HeadDataNew
        title={doc.data.meta_title}
        description={doc.data.meta_description}
        noIndex={doc.data.post_no_index ? true : false}
        image={imgSrc}
      />

      <PageHeaderImageBgNew imageData={imgHero}>
        <StyledDiv className='w-11/12 xl:max-w-screen-xl flex flex-col items-center'>
          <h1 className='font-display font-extrabold text-6xl md:text-7xl xl:text-8xl 2xl:text-9xl text-white text-center text-shadow tracking-wider'>
            Command Access Blog
          </h1>
        </StyledDiv>
      </PageHeaderImageBgNew>

      <div className='bg-white pt-12 pb-16 md:min-h-screen'>
        <div className='w-11/12 md:max-w-screen-xl mx-auto'>
          {blogPosts.map((item, index) => {
              const blogImage = getImage(item.node.data.post_hero_background)
              const datePosted = new Date(item.node.data.date_published)
              const formattedDate = datePosted.toLocaleDateString('en-US',{
                year: "numeric",
                month: "short",
                day: "numeric"
              })

              // Check if the index is even or odd to alternate the layout
              const isEven = index % 2 === 0;

              return (
                <div className={`w-full flex flex-col xl:flex-row items-center justify-center mb-24 xl:mb-28 space-x-0 xl:space-x-12 ${isEven ? '' : 'flex-row-reverse'}`}>
                  <div className="w-full xl:w-8/12">
                  <Link to={`/blog/${item.node.uid}`}>
                      <GatsbyImage
                        image={blogImage}
                        alt={blogImage.alt ? blogImage.alt : ""}
                        className="h-auto md:h-96 drop-shadow-lg mb-8 xl:mb-0"
                        objectPosition={item.node.data.post_hero_background_position}
                      />
                    </Link>
                  </div>
    
                  <div className="w-full xl:w-4/12">
                    <Link to={`/blog/${item.node.uid}`}>
                      <h2 className="font-body font-extrabold text-xl md:text-2xl text-gray-900 tracking-wider">{item.node.data.post_title}</h2>
                    </Link>
    
                    <p className="font-body text-xs text-gray-800 uppercase mt-6">PUblished on {formattedDate}</p>
    
                    <p className="font-body text-gray-900 font-medium mt-6 mb-8">{item.node.data.post_excerpt}</p>
    
                    <Link 
                      to={`/blog/${item.node.uid}`}
                      className="font-body text-lg font-extrabold text-red-800 hover:text-red-900 hover:underline"
                    >
                      Read More
                    </Link>
                  </div>
                </div> 
              )
            })}
        </div>
      </div>
    </MainContainer>
  )
}

export default BlogPage